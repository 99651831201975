import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/shared/layout-np';
import SEO from '../components/SEO/SEO';
import styled from '@emotion/styled';
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  noFeaturedWrapper,
  color,
  breakpoints,
  font,
  ContentStyles,
  ContentStylesExt,
} from '../utils/style';
import ButtonLink from '../components/shared/ButtonLink';
import Img from 'gatsby-image';
import ContentWithFluidImages from '../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../components/shared/Breadcrumbs';
import Bell from '../icons/svg/Bell';
import Parallax from '../components/shared/Parallax';

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  max-height: 600px;
  overflow: hidden;
  border-radius: 24px;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  .prev-next {
    min-height: 70px;
    padding-bottom: 20px;
  }
  .next-button {
    float: right;
  }
`;

const Bg = styled('div')`
  border-top: 1px solid ${color.transparentWhite1};
  padding: 15px 0 5px;
`;
const RelatedWrapper = styled('div')`
  padding: 30px 0;
  margin-top: 10px;
  text-align: center;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  li {
    width: 23%;
    text-align: left;
  }

  .recent-name {
    font-size: 1rem;
    font-weight: ${font.headingWeight};
    margin: 20px 0 10px;
  }

  .recent-excerpt {
    font-size: 14px;
    line-height: 20px;
    max-height: 140px;
    overflow-y: hidden;
  }

  @media (max-width: ${breakpoints.md}) {
    ul {
      margin: 10px 0;
    }
    li {
      width: 48%;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    li {
      width: 100%;
    }
  }
`;

const PostTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost;
  const metaTitle =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_title
      ? post.yoast_meta.yoast_wpseo_title
      : post.title + +'- ' + process.env.OWNER;
  const metaDesc =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc
      ? post.yoast_meta.yoast_wpseo_metadesc
      : '';
  const relatedPosts = data.allWordpressPost;
  const placeholder = data.placeholder;
  const relatedIds = pageContext.related;
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const prevPost = pageContext.prev;
  const nextPost = pageContext.next;
  const socialImage = post.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        key="seo"
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {post.featured_media &&
        post.featured_media.localFile &&
        post.featured_media.localFile.childImageSharp &&
        post.featured_media.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper key="featured-wrapper">
            <Img
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              alt={post.featured_media.alt_text}
            />
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper key="no-featured-wrapper">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper key="content-wrapper">
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ? (
            ContentWithFluidImages(post.content, media)
          ) : (
            <div
              key="content"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
          )}
          {(prevPost.slug || nextPost.slug) && (
            <div className="prev-next">
              {prevPost.slug &&
                !prevPost.slug.includes('provider-carousel') && (
                  <ButtonLink
                    className="button gradient-button prev-button"
                    iconName="arrow-left"
                    iconLeft={true}
                    to={prevPost.slug + '/'}
                    text="ก่อนหน้า"
                  />
                )}
              {nextPost.slug &&
                !nextPost.slug.includes('provider-carousel') && (
                  <ButtonLink
                    className="button gradient-button next-button"
                    to={nextPost.slug + '/'}
                    text="ต่อไป"
                  />
                )}
            </div>
          )}
        </ContentWrapper>

        {relatedIds.length > 0 && (
          <Bg key="related-bg">
            <RelatedWrapper>
              <h3 className="h3 with-icon">
                <Bell />
                <span>อัพเดท บทความ ข่าวสารจาก GClub</span>
              </h3>
              <ul>
                {relatedPosts.edges.map((item, index) => (
                  <li key={item.node.slug + index}>
                    <Link to={'/' + item.node.slug + '/'}>
                      {item.node.featured_media &&
                      item.node.featured_media.localFile &&
                      item.node.featured_media.localFile.childImageSharp &&
                      item.node.featured_media.alt_text ? (
                        <div className="img-wrap">
                          <Img
                            fluid={
                              item.node.featured_media.localFile.childImageSharp
                                .fluid
                            }
                            alt={item.node.featured_media.alt_text}
                          />
                        </div>
                      ) : (
                        <div className="img-wrap">
                          <Img
                            fluid={placeholder.childImageSharp.fluid}
                            alt={item.node.label}
                          />
                        </div>
                      )}
                      <div
                        className="recent-name"
                        dangerouslySetInnerHTML={{ __html: item.node.title }}
                      />
                      <div
                        className="recent-excerpt"
                        dangerouslySetInnerHTML={{ __html: item.node.excerpt }}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </RelatedWrapper>
          </Bg>
        )}
      </WrapperDesktop>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: {
        wordpress_id: { in: $related }
        acf: { post_template: { ne: "service_post_carousel" } }
      }
      limit: 4
    ) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
